.box {
  border-radius: 10px;
  padding: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .box {
    height: auto;
    margin-bottom: 20px;
  }

  .box h2 {
    font-size: 1.2rem;
  }
}

.box.active {
  background-color: #ADD8E6;
  cursor: pointer;
}

